import moment from "moment";
import { createSelector } from "reselect";
import {ACCOUNT_CURRENT_SUCCEEDED, SET_ASSIGNED, CLEAR_ASSIGNED} from "../../constants/ActionTypes";

const initialState = {
  phoneNumbersAll: [],
  isAssigned: false,
  isLoadedPhoneNumbers: false,
  isTrial: false,
  trialFinishDate: null,
  accountName: '',
  currency: null,
  videoKey: '',
  bulkMessagingEnabled: false,
  popupEnabled: false,
  nfcSignsEnabled: false,
  status: null
};

const accounts = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_CURRENT_SUCCEEDED:
      return {
        ...state,
        phoneNumbersAll: action.accounts?.phoneNumbers ? [...action.accounts.phoneNumbers] : [],
        isTrial: action.accounts.trial,
        trialFinishDate: action.accounts.trialFinishDate,
        accountName: action.accounts.name,
        isLoadedPhoneNumbers: true,
        videoKey: action.accounts.videoKey,
        bulkMessagingEnabled: action.accounts.bulkMessagingEnabled,
        popupEnabled: action.accounts.popupEnabled,
        nfcSignsEnabled: action.accounts.nfcSignsEnabled,
        status: action.accounts.status,
        currency: action.accounts.currency
      };
    case CLEAR_ASSIGNED:
      return {
        ...state,
        isAssigned: false
      };
    case SET_ASSIGNED:
      return {
        ...state,
        isAssigned: true
      };
    default:
      return state;
  }
};

export const accountsSelector = state => state.accounts;

export const isTrialExpiredSelector = createSelector(
    accountsSelector,
    accounts => accounts.trialFinishDate && moment().isAfter(moment(accounts.trialFinishDate))
);

export const trialTimeSelector = createSelector(
    accountsSelector,
    accounts => moment(accounts.trialFinishDate).from(moment())
);

export default accounts;
